import React from 'react';
import { Link } from 'gatsby';

const HeaderContent = () => (
  <div className="wrapper">
    <Link to="/" className="logo dc">DanielCare - Licensed Home Health Care</Link>
    <ul className="meta">
      <li className="employment-button"><Link to="/employment/" className="button dc"><i className="fa fa-arrow-circle-right"></i>Employment for<br/>Home Health Care</Link></li>
    </ul>
    <div className="nav-offices">
      <div>
        <h2>Phone</h2>
        <ul className="nav-contact">
          <li className="phone"><i className="fa fa-phone" title="Phone"></i>203.658.8080</li>
        </ul>
      </div>
      <div>
        <h2>Fax</h2>
        <ul className="nav-contact">
          <li className="fax"><i className="fa fa-fax" title="Phone"></i>203.817.0080</li>
        </ul>
      </div>
    </div>
  </div>
)

export default HeaderContent
